import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Link from "next/link";
import Image from "next/image";
import { useLazyQuery } from "@apollo/client";
import { Dropdown } from "react-bootstrap";
import UserIcon from "@heroicons/react/solid/UserIcon";
import IdentificationIcon from "@heroicons/react/solid/IdentificationIcon";
import HeartIcon from "@heroicons/react/solid/HeartIcon";
import LogoutIcon from "@heroicons/react/solid/LogoutIcon";
import { useStateContext } from "../../../context";
import { useCookie } from "next-cookie";
import { TOKEN_REDIRECT_PANEL } from "../../../action/user";
import {
  BlogIcon,
  HomeIcon,
  PriceIcon,
  TermIcon,
  VerticalLineIcon,
} from "../Icon";

const Button = dynamic(() => import("react-bootstrap/Button"), { ssr: false });
const MenuModal = dynamic(() => import("./MenuModal"), { ssr: false });
const SearchModal = dynamic(() => import("./SearchModal"), { ssr: false });
const MenuIcon = dynamic(() => import("@heroicons/react/solid/MenuIcon"), {
  ssr: false,
});

const HeaderSearch = (props) => {
  const { isLanding } = props;
  const router = useRouter();
  const token = useCookie().get("token");
  const { search } = router.query;
  const [state, setState] = useStateContext();
  const [searchInputValue, setSearchInputValue] = useState(search || "");
  const [suggestionModalOpen, setSuggestionModalOpen] = useState(false);
  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => storePathValues, [router.asPath]);

  const [getToken] = useLazyQuery(TOKEN_REDIRECT_PANEL, {
    onCompleted: (resp) => {
      window.open(
        `${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/masuk/${resp.token_redirect_panel}`
      );
    },
    onError: (resp) => {
      if (resp.message === "Unauthenticated") {
        window.open(`${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/listing-saya`);
      }
    },
    fetchPolicy: "no-cache",
  });

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem("currentPath");
    storage.setItem("prevPath", prevPath);
    // Set the current path value by looking at the browser's location object.
    storage.setItem("currentPath", globalThis.location.pathname);
  }

  function handleLoginClick() {
    TRACK_LOGIN_SELECTED();

    setState({ ...state, loginModal: true });
  }

  const handleToggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleClickOutsideDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (showDropdown && !e.target.closest(".dropdown-menu")) {
        handleClickOutsideDropdown();
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdown]);

  const handleRedirecting = () => {
    getToken();
  };

  const user = state.user;
  const isLoginPage = router.pathname === "/login";
  const isAgentPage = router.pathname.split("/")[1] === "agent";
  return (
    <>
      <header>
        <div className={`px-0 ${!isLanding ? "bg-white shadow-sm" : ""}`}>
          <div className="container">
            <div className="py-2">
              <div className="d-flex gap-4 align-items-center justify-content-between">
                <Link href="/">
                  <a>
                    {isAgentPage ? (
                      <Image
                        src="/logo_rmd.png"
                        alt="logo rumahdewi"
                        width="200px"
                        height="60px"
                        quality={100}
                        placeholder="blur"
                        blurDataURL="/logo_rmd.png"
                      />
                    ) : (
                      <Image
                        src="/logo_rmd_blue.png"
                        alt="logo rumahdewi"
                        width="200px"
                        height="60px"
                        quality={100}
                        placeholder="blur"
                        blurDataURL="/logo_rmd_blue.png"
                      />
                    )}
                  </a>
                </Link>

                <div className={`d-none d-sm-flex gap-4 align-items-center`}>
                  <Link href="/">
                    <a
                      className={`d-flex align-items-center gap-1 position-relative ${
                        isAgentPage ? "text-white" : "text-secondary"
                      }`}
                    >
                      <HomeIcon
                        width={15}
                        height={15}
                        className={`${
                          isAgentPage ? "text-white" : "text-secondary"
                        }`}
                      />
                      <Button
                        variant="link"
                        className={`fw-medium p-0 text-decoration-none ${
                          isAgentPage ? "text-white" : "text-secondary"
                        }`}
                        style={{ fontSize: "15px" }}
                      >
                        Home
                      </Button>
                    </a>
                  </Link>

                  <Link href={"https://blog.rumahdewi.com"}>
                    <a
                      className={`d-flex align-items-center gap-1 position-relative ${
                        isAgentPage ? "text-white" : "text-secondary"
                      }`}
                    >
                      <BlogIcon
                        width={15}
                        height={15}
                        className={`${
                          isAgentPage ? "text-white" : "text-secondary"
                        }`}
                      />
                      <Button
                        variant="link"
                        className={`fw-medium p-0 text-decoration-none position-relative
                        ${isAgentPage ? "text-white" : "text-secondary"}`}
                        style={{ fontSize: "15px" }}
                      >
                        Blog
                      </Button>
                    </a>
                  </Link>

                  <Link href={`/packages`}>
                    <a
                      className={`d-flex align-items-center gap-1 position-relative ${
                        isAgentPage ? "text-white" : "text-secondary"
                      }`}
                    >
                      <PriceIcon
                        width={15}
                        height={15}
                        className={`${
                          isAgentPage ? "text-white" : "text-secondary"
                        }`}
                      />
                      <Button
                        variant="link"
                        className={`fw-medium p-0 text-decoration-none position-relative 
                        ${isAgentPage ? "text-white" : "text-secondary"}`}
                        style={{ fontSize: "15px" }}
                      >
                        Harga
                      </Button>
                    </a>
                  </Link>

                  <Link href={`/term-policy`}>
                    <a
                      className={`d-flex align-items-center gap-1 position-relative ${
                        isAgentPage ? "text-white" : "text-secondary"
                      }`}
                    >
                      <TermIcon
                        width={15}
                        height={15}
                        className={`${
                          isAgentPage ? "text-white" : "text-secondary"
                        }`}
                      />
                      <Button
                        variant="link"
                        className={`fw-medium p-0 text-decoration-none position-relative 
                        ${isAgentPage ? "text-white" : "text-secondary"}`}
                        style={{ fontSize: "15px" }}
                      >
                        Ketentuan
                      </Button>
                    </a>
                  </Link>
                  {!isLoginPage ? (
                    <div
                      className={`position-relative ${
                        isAgentPage ? "text-white" : "text-secondary"
                      }`}
                    >
                      <VerticalLineIcon />
                    </div>
                  ) : (
                    ""
                  )}

                  {!isLoginPage ? (
                    !!user && token ? (
                      <div className="align-items-center">
                        {showDropdown && (
                          <div
                            className="overlay position-fixed top-0 start-0 w-100 h-100"
                            style={{
                              zIndex: 9998,
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={handleClickOutsideDropdown}
                          />
                        )}

                        <Dropdown
                          align="end"
                          show={showDropdown}
                          className="align-items-center"
                        >
                          <Dropdown.Toggle
                            className="d-flex align-items-center p-2"
                            style={{
                              border: "none",
                              fontSize: "15px",
                              color: "#21314A",
                              fontWeight: "500",
                              zIndex: 9999,
                              backgroundColor: showDropdown
                                ? "#d9d9d9"
                                : "transparent",
                              height: "30px",
                            }}
                            onClick={handleToggleDropdown}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{}}
                            >
                              <div
                                className="rounded-circle bg-black d-flex align-items-center justify-content-center position-absolute"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  marginRight: "10px",
                                  border: "2px solid white",
                                  transform: "translateX(-20%)",
                                  zIndex: 9999,
                                }}
                              >
                                <i
                                  className="bi bi-person-fill text-white"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </div>
                              <div
                                className={`w-100 position-relative fw-medium text-decoration-none 
                        ${
                          isAgentPage || showDropdown
                            ? "text-white"
                            : "text-secondary"
                        }`}
                                style={{
                                  paddingLeft: "50px",
                                  paddingRight: "10px",
                                  zIndex: 9999,
                                }}
                              >
                                {user.first_name} {user.last_name}
                              </div>
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className="mt-2 d-flex flex-column gap-3"
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              borderRadius: "10px",
                              zIndex: 9999,
                            }}
                          >
                            <Dropdown.Item>
                              <span
                                className="d-flex align-items-center gap-2 justify-content-between"
                                onClick={handleRedirecting}
                                style={{ color: "#606060" }}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src="/assets/profile.svg"
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: ".1rem" }}
                                  />{" "}
                                  My Profile
                                </div>
                                <i class="bi bi-chevron-right"></i>
                              </span>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <span
                                className="d-flex align-items-center gap-2 justify-content-between"
                                onClick={handleRedirecting}
                                style={{ color: "#606060" }}
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <img
                                    src="/assets/listing.svg"
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: ".1rem" }}
                                  />
                                  Listing Saya
                                </div>
                                <i class="bi bi-chevron-right"></i>
                              </span>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <Link href="/wishlist">
                                <a
                                  className="d-flex align-items-center gap-2 justify-content-between"
                                  style={{ color: "#606060" }}
                                >
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      src="/assets/heart.svg"
                                      width="20px"
                                      height="20px"
                                      style={{ marginBottom: ".1rem" }}
                                    />
                                    Wishlist
                                  </div>
                                  <i className="bi bi-chevron-right"></i>
                                </a>
                              </Link>
                            </Dropdown.Item>

                            <Dropdown.Item>
                              <Link href="/logout">
                                <a
                                  className="d-flex align-items-center gap-2"
                                  style={{ color: "#D10000" }}
                                >
                                  <img
                                    src="/assets/logout.svg"
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: ".1rem" }}
                                  />
                                  Logout
                                </a>
                              </Link>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : (
                      <Button
                        color="primary"
                        className="rounded-pill posiiton-relative"
                        onClick={() => router.push("/login")}
                      >
                        <div className="d-flex gap-2 position-relative">
                          <i className="bi bi-box-arrow-in-left"></i>
                          <p className="mb-0">Masuk</p>
                        </div>
                      </Button>
                    )
                  ) : (
                    ""
                  )}
                </div>

                <Button
                  onClick={() => setMenuModalOpen(true)}
                  variant="link"
                  className={`d-sm-none z-100 position-relative ${
                    isAgentPage ? "text-white" : ""
                  }`}
                >
                  <MenuIcon width="25px" height="25px" />
                </Button>
              </div>
            </div>

            {suggestionModalOpen && (
              <SearchModal
                currentInput={searchInputValue}
                setCurrentInputSearchValue={setSearchInputValue}
                modalOpenStatus={suggestionModalOpen}
                setModalOpenStatus={setSuggestionModalOpen}
              />
            )}

            {menuModalOpen && (
              <MenuModal
                modalOpenStatus={menuModalOpen}
                setModalOpenStatus={setMenuModalOpen}
              />
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderSearch;
